h3[data-v-09c68b87] {
  color: #666666;
  font-size: 16px;
}
.list[data-v-09c68b87] {
  width: 188px;
  margin: 30px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  color: #666666;
  text-align: left;
  line-height: 24px;
}
.list label[data-v-09c68b87] {
    display: inline-block;
    width: 46px;
}
